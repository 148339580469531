$font-family: "Poppins", sans-serif;

//font-size
$fs-57: 57px;
$fs-45: 45px;
$fs-36: 36px;
$fs-32: 32px;
$fs-28: 28px;
$fs-26: 26px;
$fs-24: 24px;
$fs-22: 22px;
$fs-20: 20px;
$fs-18: 18px;
$fs-16: 16px;
$fs-14: 14px;
$fs-12: 12px;
$fs-11: 11px;

$fw-light: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;

// Mixin

$phone: 320px;
$large-phone: 500px;
$tablet: 1024px;
$laptop: 1200px;
$desktop: 1400px;
$desktop2: 1600px;
$large-display: 1920px;

@mixin fontSize($size) {
  $result: $size;
  @media only screen and (min-width: $large-display) {
    $result: $size;
    font-size: $result !important;
  }

  @media only screen and (max-width: $large-display) {
    $result: $size;
    font-size: $result !important;
  }

  @media only screen and (max-width: $tablet) {
    $result: calc(3px - $size);
    font-size: $result !important;
  }

  @media only screen and (max-width: $large-phone) {
    $result: calc($size - 6px);
    font-size: $result !important;
  }

  @media only screen and (max-width: $phone) {
    $result: calc(1px - $size);
    font-size: $result !important;
  }
}

.fs-11 {
  @include fontSize($fs-11);
  font-weight: $fw-light !important;
}
.fs-sm-light {
  @include fontSize($fs-12);
  font-weight: $fw-light !important;
}

.fs-sm-med {
  @include fontSize($fs-12);
  font-weight: $fw-medium !important;
}

.fs-sm-semibold {
  @include fontSize($fs-12);
  font-weight: $fw-semibold !important;
}

.fs-normal-light {
  @include fontSize($fs-14);
  font-weight: $fw-light !important;
}

.fs-normal-med {
  @include fontSize($fs-14);
  font-weight: $fw-medium !important;
}

.fs-normal-semibold {
  @include fontSize($fs-14);
  font-weight: $fw-semibold !important;
}

.fs-sm-lg-light {
  @include fontSize($fs-16);
  font-weight: $fw-light !important;
}

.fs-sm-lg-med {
  @include fontSize($fs-16);
  font-weight: $fw-medium !important;
}

.fs-sm-lg-semibold {
  @include fontSize($fs-16);
  font-weight: $fw-semibold !important;
}

.fs-med-lg-light {
  @include fontSize($fs-18);
  font-weight: $fw-light !important;
}

.fs-med-lg-med {
  @include fontSize($fs-18);
  font-weight: $fw-medium !important;
}

.fs-med-lg-semibold {
  @include fontSize($fs-18);
  font-weight: $fw-semibold !important;
}

.fs-med-slg-medium {
  @include fontSize($fs-20);
  font-weight: $fw-medium !important;
}

.fs-lg-semibold {
  @include fontSize($fs-22);
  font-weight: $fw-semibold !important;
}

.fs-xlg-light {
  @include fontSize($fs-24);
  font-weight: $fw-light !important;
}

.fs-xlg-semibold {
  @include fontSize($fs-24);
  font-weight: $fw-semibold !important;
}

.fs-xlg-bold {
  @include fontSize($fs-24);
  font-weight: $fw-bold !important;
}

.fs-sm-heading-med {
  @include fontSize($fs-26);
  font-weight: $fw-medium !important;
}

.fs-sm-heading-semibold {
  @include fontSize($fs-26);
  font-weight: $fw-semibold !important;
}

.fs-med-heading-light {
  @include fontSize($fs-28);
  font-weight: $fw-light !important;
}

.fs-lg-heading-light {
  @include fontSize($fs-32);
  font-weight: $fw-light !important;
}

.fs-xlg-sm-heading-light {
  @include fontSize($fs-36);
  font-weight: $fw-light !important;
}

.fs-xlg-med-heading-semibold {
  @include fontSize($fs-45);
  font-weight: $fw-semibold !important;
}

.fs-xlg-lg-heading-light {
  @include fontSize($fs-57);
  font-weight: $fw-light !important;
}
